import React, { useState } from 'react';

function ChatContainer() {
  const [messages, setMessages] = useState([
    { sender: 'bot', text: "Welcome. Curious about Satyarth's career? Ask Away!" },
  ]);
  const [userInput, setInput] = useState('');

  const sendMessage = async () => {
    if (userInput.trim() === '') return;

    const newMessages = [...messages, { sender: 'user', text: userInput }];
    setMessages(newMessages);
    setInput('');

    const userMessage = userInput

    const url = "https://satyarth-portfolio.com/backend/predict"
    const body = JSON.stringify({ question: userMessage })

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });

      const data = await response.json();

      if ("answer" in data) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: data['answer'] },
        ]);
      }

      else {
        setMessages((prevMessages) => [
          ...prevMessages,
          { sender: 'bot', text: 'Sorry looks like the backend service is down.' },
        ]);
      }


    } catch (error) {
      console.error('Error fetching bot response:', error);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: 'bot', text: 'Sorry, something went wrong. Please try again later.' },
      ]);
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">AI Assistant@Satyarth:~$ |</div>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`chat-message ${msg.sender === 'user' ? 'user' : 'bot'}`}
          >
            <span className="message-prefix">
              {msg.sender === 'user' ? 'You: ' : 'AI: '}
            </span>
            {msg.text}
          </div>
        ))}
      </div>
      <div className="chat-input">
        <span className="input-prefix">You:~$</span>
        <input
          type="text"
          value={userInput}
          placeholder=""
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
        />
      </div>
    </div>
  );
}

export default ChatContainer;