// Skills.js
import React from 'react';
import './styles.css';
import airflowLogo from './assets/apacheairflow-original-wordmark.svg'
import pythonLogo from './assets/python-original-wordmark.svg';  // example logos
import reactLogo from './assets/react-original-wordmark.svg';
import awsLogo from './assets/amazonwebservices-original-wordmark.svg';
import azureLogo from './assets/azure-original-wordmark.svg'
import kerasLogo from './assets/keras-original-wordmark.svg'
import tfLogo from './assets/tensorflow-original-wordmark.svg'
import pytorchLogo from './assets/pytorch-original-wordmark.svg'
import sklearnLogo from './assets/scikitlearn-original.svg'
import pandasLogo from './assets/pandas-original-wordmark.svg'
import jupyterLogo from './assets/jupyter-original-wordmark.svg'
import nodeLogo from './assets/nodejs-original-wordmark.svg'
import javascriptLogo from './assets/javascript-original.svg'
import gcpLogo from './assets/googlecloud-original-wordmark.svg'
import grafanaLogo from './assets/grafana-original-wordmark.svg'
import githubLogo from './assets/github-original-wordmark.svg'
import gitlabLogo from './assets/gitlab-original-wordmark.svg'
import openaiLogo from './assets/OpenAI.svg'
import huggingfaceLogo from './assets/hf-original.svg'
import redisLogo from './assets/redis-original-wordmark.svg'
import flaskLogo from './assets/flask-original-wordmark.svg'
import fastapiLogo from './assets/fastapi-original-wordmark.svg'
import terraformLogo from './assets/terraform-original-wordmark.svg'
import tableauLogo from './assets/tableau.svg'

const skillsData = [
    {
        name: 'Python',
        logo: pythonLogo,
        link: 'https://www.python.org/',
    },
    {
      name: 'TensorFlow',
      logo: tfLogo,
      link: 'https://www.tensorflow.org/',
    },
    {
        name: 'Keras',
        logo: kerasLogo,
        link: 'https://keras.io/',
      },
    {
      name: 'PyTorch',
      logo: pytorchLogo,
      link: 'https://pytorch.org/',
    },
    {
      name: 'scikit-learn',
      logo: sklearnLogo,
      link: 'https://scikit-learn.org/',
    },
    {
        name: 'Pandas',
        logo: pandasLogo,
        link: 'https://pandas.pydata.org/',
      },
    {
      name: 'Huggingface',
      logo: huggingfaceLogo,
      link: 'https://huggingface.co/',
    },
    {
        name: 'Jupyter',
        logo: jupyterLogo,
        link: 'https://jupyter.org/',
      },
      {
        name: 'Open AI',
        logo: openaiLogo,
        link: 'https://openai.com/',
      },
      {
        name: 'Tableau',
        logo: tableauLogo,
        link: 'https://www.tableau.com/',
      },
      {
        name: 'Grafana',
        logo: grafanaLogo,
        link: 'https://grafana.com/',
      },
      {
        name: 'JavaScript',
        logo: javascriptLogo,
        link: 'https://developer.mozilla.org/en-US/docs/Web/JavaScript',
      },
      {
        name: 'Node.js',
        logo: nodeLogo,
        link: 'https://nodejs.org/en',
      },
      {
        name: 'React',
        logo: reactLogo,
        link: 'https://react.dev/',
      },
      {
        name: 'Redis',
        logo: redisLogo,
        link: 'rhttps://redis.io/',
      },
      {
        name: 'Flask',
        logo: flaskLogo,
        link: 'https://flask.palletsprojects.com/en/3.0.x/'
      },
      {
        name: 'FastAPI',
        logo: fastapiLogo,
        link: 'https://fastapi.tiangolo.com/',
      },
      {
        name: 'Airflow',
        logo: airflowLogo,
        link: 'https://airflow.apache.org/',
      },
      {
        name: 'Azure',
        logo: azureLogo,
        link: 'https://azure.microsoft.com/en-us',
      },
      {
        name: 'GCP',
        logo: gcpLogo,
        link: 'https://cloud.google.com/',
      },
      {
        name: 'AWS',
        logo: awsLogo,
        link: 'https://aws.amazon.com/',
      },
      {
        name: 'Terraform',
        logo: terraformLogo,
        link: 'https://www.terraform.io/',
      },
      {
        name: 'Github',
        logo: githubLogo,
        link: 'https://github.com/about',
      },
      {
        name: 'Gitlab',
        logo: gitlabLogo,
        link: 'https://about.gitlab.com/',
      }

  ];

function Skills() {
  return (
    <div className="skills-container">
      <h3 className="skills-title">Skills</h3>
      <div className="skills-logos">
      {skillsData.map((skill) => (
          <div className="skill-item" key={skill.name}>
            <a href={skill.link} target="_blank" rel="noopener noreferrer">
              <img src={skill.logo} alt={`${skill.name} logo`} className="skill-logo" />
            </a>
          </div>
        ))}
        {/* <div className="skill-item">
        <div className="skill-item">
          <img src={flaskLogo} alt="Flask" className="skill-logo" />
        </div>
        <div className="skill-item">
          <img src={fastapiLogo} alt="FastAPI" className="skill-logo" />
        </div>
        <div className="skill-item">
          <img src={azureLogo} alt="Azure" className="skill-logo" />
        </div>
        <div className="skill-item">
          <img src={gcpLogo} alt="GCP" className="skill-logo" />
        </div>
        <div className="skill-item">
          <img src={awsLogo} alt="AWS" className="skill-logo" />
        </div>
        <div className="skill-item">
          <img src={terraformLogo} alt="Terraform" className="skill-logo" />
        </div>
        <div className="skill-item">
          <img src={githubLogo} alt="Github" className="skill-logo" />
        </div>
        <div className="skill-item">
          <img src={gitlabLogo} alt="Gitlab" className="skill-logo" />
        </div> */}
      </div>
    </div>
  );
}

export default Skills;