import React from 'react';
import ChatContainer from './ChatContainer';
import ContactMe from './ContactMe'
import './styles.css';
import profileImage from './assets/profile.jpg';
import Skills from './Skills';

function App() {
  return (
    <div className="app">
      <header className="header">
        <h1 className="title">Satyartha Prakash Poppasani</h1>
        <h2 className="subtitle">Machine Learning Engineer</h2>
        <div className="profile-image-container">
          <img src={profileImage} alt="Satyarth" className="profile-image" />
        </div>
      </header>
      <main className="main-content">
        <div className="branding">
          <p>
            Welcome to my Portfolio website.<br />
            <br />
            I'm a Machine Learning Engineer with a focus 
            on Conversational AI solutions.<br />
            <br />
            This website is intentionally kept simple.  
            Use the chat window on the right to chat about anything related to my 
            professional career.<br />
            <br />
            If you would like to reach out to me directly
            please use the email below.
          </p>
        </div>
        <ChatContainer />
      </main>
      <Skills />
      <ContactMe />
    </div>
  );
}

export default App;