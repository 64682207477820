import React from 'react';
import './styles.css';

function ContactMe() {
  return (
    <footer className="contact-me">
      <h3>Contact Me</h3>
      <p>Email: <a href="mailto:psatyarthaprakash@gmail.com">psatyarthaprakash@gmail.com</a></p>
      <p>LinkedIn: <a href="https://www.linkedin.com/in/poppasani/" target="_blank" rel="noopener noreferrer">linkedin.com/in/poppasani</a></p>
    </footer>
  );
}

export default ContactMe;